import { Link } from 'gatsby';
import React from 'react';

import { BaseWrapper, Card, CardsWrapper } from '@/components';
import { useOperators } from '@/utils/operators';

const Component = () => {
  const operators = useOperators();
  return (
    <div className="py-3">
      <CardsWrapper>
        {operators.map((operator) => (
          <Card key={operator.path} title={operator.name} size="sm">
            <div className="p-3 flex justify-center">
              <Link to={`/${operator.path}/`}>Go to operator</Link>
            </div>
          </Card>
        ))}
      </CardsWrapper>
    </div>
  );
};

const Wrapper = () => (
  <BaseWrapper>
    <Component />
  </BaseWrapper>
);

export default Wrapper;
